import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  sectionWrapper,
  sectionTitle,
  section,
  successListContainer,
  whiteBackground,
  professionalServiceSubpageSection,
  professionalServiceSubpageSectionWrapper
} from 'styles/customPageSection.module.css'
import { composeClasses } from 'utils'

const customPageSectionComponent = ({ items, title, subtitle, hasWhiteBackground, isProfessionalServiceSubpage }) => {
  return (
    <div className={composeClasses(isProfessionalServiceSubpage === true ? professionalServiceSubpageSectionWrapper : sectionWrapper, hasWhiteBackground === true ? whiteBackground : '')}>
      <div className={composeClasses(isProfessionalServiceSubpage === true ? professionalServiceSubpageSection : section)}>
        <h5 className={sectionTitle}>{title}</h5>
        <h2>{subtitle}</h2>
        <span className={successListContainer}>{parse(items)}</span>
      </div>
    </div>
  )
}

customPageSectionComponent.propTypes = {
  render: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default customPageSectionComponent
