import React from 'react'
import PropTypes from 'prop-types'
import {
  headingColor,
  leadTextStyle,
  sectionWrapper,
  sectionTitle
} from 'styles/whatWeOfferContentSectionStyles.module.css'
import {
  section,
  cardContainer
} from '../styles/cloudNativeSoftwareEngineering.module.css'

const WhatWeOfferContentSection = ({ items, leadTitle, render, title, backgroundColor }) => {
  return (
    <div style={{ backgroundColor: backgroundColor }}>
      <div
        className={sectionWrapper}>
        <div className={sectionTitle}>
          <span className={leadTextStyle}>{leadTitle}</span>
          <h2 className={headingColor}>{title}</h2>
        </div>
        <div className={section}>
          <div className={cardContainer}>
            {render && items.map((item, index) => {
              return render(item, index)
            })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

WhatWeOfferContentSection.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  leadTitle: PropTypes.string.isRequired,
  render: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default WhatWeOfferContentSection
