import React from 'react'
import PropTypes from 'prop-types'
import { CONTACT_FORM_LINK } from 'utils/constants'

import {
  ctaChatContainer
} from 'styles/cloudNativeSoftwareEngineering.module.css'

const ContactFormButton = ({ ctaText }) => {
  return (
    <a
      className={ctaChatContainer}
      href={CONTACT_FORM_LINK}
      target='_blank'
    >
      {ctaText}
    </a>
  )
}

ContactFormButton.propTypes = {
  ctaText: PropTypes.string.isRequired
}

export default ContactFormButton
