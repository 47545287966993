import React from 'react'
import PropTypes from 'prop-types'
import {
  sectionWrapper,
  sectionTitle,
  section
} from 'styles/cloudNativeClients.module.css'

const CloudNativeClientsComponent = ({ customWidth, items, render, title, backgroundColor }) => {
  return (
    <div
      className={sectionWrapper}
      style={{
        backgroundColor: backgroundColor
      }}>
      <div className={sectionTitle}>
        <h2>{title}</h2>
      </div>
      <div>
        <div
          className={section}
          style={{ maxWidth: customWidth }}>
          {render && items.map((item, index) => {
            return render(item, index)
          })
          }
        </div>
      </div>
    </div>
  )
}

CloudNativeClientsComponent.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  customWidth: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  render: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default CloudNativeClientsComponent
