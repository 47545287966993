// extracted by mini-css-extract-plugin
export var pageWrapper = "cloudNativeSoftwareEngineering-module--pageWrapper--6GlB6";
export var cloudNativeHeadingWrapper = "cloudNativeSoftwareEngineering-module--cloudNativeHeadingWrapper--fn8wo";
export var ctaChatContainer = "cloudNativeSoftwareEngineering-module--ctaChatContainer--GGUqz";
export var leadTextStyle = "cloudNativeSoftwareEngineering-module--leadTextStyle--YO4ek";
export var headingColor = "cloudNativeSoftwareEngineering-module--headingColor--jyeEy";
export var contentWrapper = "cloudNativeSoftwareEngineering-module--contentWrapper--4hlAK";
export var successListContainer = "cloudNativeSoftwareEngineering-module--successListContainer--aoKUQ";
export var desktopImage = "cloudNativeSoftwareEngineering-module--desktopImage--2OvEv";
export var mobileImage = "cloudNativeSoftwareEngineering-module--mobileImage--wJcek";
export var hoverContainer = "cloudNativeSoftwareEngineering-module--hoverContainer--5HzFy";
export var cardContainer = "cloudNativeSoftwareEngineering-module--cardContainer--LkQdM";
export var cardFront = "cloudNativeSoftwareEngineering-module--cardFront--pVxJf";
export var section = "cloudNativeSoftwareEngineering-module--section--j7FEP";
export var cards = "cloudNativeSoftwareEngineering-module--cards--iMaJe";
export var card = "cloudNativeSoftwareEngineering-module--card--I-X5O";
export var cardBody = "cloudNativeSoftwareEngineering-module--cardBody--ogC0D";
export var cardBack = "cloudNativeSoftwareEngineering-module--cardBack--gD2YU";
export var clientsLogoSection = "cloudNativeSoftwareEngineering-module--clientsLogoSection--gCrDK";