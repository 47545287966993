import React from 'react'
import PropTypes from 'prop-types'
import {
  sectionWrapper,
  section,
  sectionImageContainer,
  sectionDescriptionContainer,
  professionalSectionImageContainer,
  professionalSectionDescriptionContainer,
  paddedSection
} from 'styles/whatIsContentSection.module.css'

const WhatIsContentSection = ({ items, image, render, backgroundColor, professionalServices }) => {
  return (
    <>
      {professionalServices ? <div
        className={paddedSection}
        style={{ backgroundColor: backgroundColor }}>
        <div
          className={sectionWrapper}
        >
          <div className={section}>
            <div className={professionalServices ? professionalSectionDescriptionContainer : sectionDescriptionContainer}>
              {render && items.map((item, index) => {
                return render(item, index)
              })
              }
            </div>
            <div className={professionalServices ? professionalSectionImageContainer : sectionImageContainer}>
              <img src={image} />
            </div>
          </div>
        </div>
      </div> : <div
        className={paddedSection}
        style={{ backgroundColor: backgroundColor }}>
        <div
          className={sectionWrapper}
        >
          <div className={section}>
            <div className={sectionImageContainer}>
              <img src={image} />
            </div>
            <div className={sectionDescriptionContainer}>
              {render && items.map((item, index) => {
                return render(item, index)
              })
              }
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

WhatIsContentSection.propTypes = {
  backgroundColor: PropTypes.string,
  image: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  professionalServices: PropTypes.bool,
  render: PropTypes.func
}

export default WhatIsContentSection
